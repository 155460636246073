@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next,
#__next > div {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  line-height: 1.6;
  font-size: 18px;
  /*height: 100%;*/
  min-height: 100%;
}

* {
  box-sizing: border-box;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

/* Ellipsis */
.flex-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 30px 0;
  width: 260px;
  height: 100%;
}

.long-and-truncated {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0 auto;
  height: 100%;
}

/*
.rich-text-preview p :not(img) {
  display: contents;
}
*/

.rich-text-preview p :not(img):not(span) {
  display: contents;
}

.rich-text-preview p img {
  display: inline;
}

h1.rich-text-preview p strong,
h2.rich-text-preview p strong,
h3.rich-text-preview p strong {
  font-weight: 400;
}

.pencil-cursor {
  cursor:
    url('../public/images/pencil-cursor.png') 0 15,
    auto;
}

.eraser-cursor {
  cursor:
    url('../public/images/eraser.png') 0 15,
    auto;
}

.select-cursor {
  cursor: 'pointer';
}

.moveable-control-box {
  --moveable-color: #333 !important;
}

.edit-text-mode {
  --moveable-color: #6956e5 !important;
}

::-webkit-scrollbar {
  position: absolute;
  width: 6px;
  height: 100%;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ebedef;
}
