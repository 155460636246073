body {
  position: 'fixed';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tox-notifications-container {
  display: none;
}