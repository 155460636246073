.medium {
  font-weight: 400;
  font-size: 14px;
  color: 'rgba(159, 162, 180, 1)';
}

.b1 {
  font-size: 16px;
  line-height: 16px;
}

.bold {
  font-weight: 700;
}